* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body{
  background-color: #cecece;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

html, body, #root{
  height: 100%;
}